import Permissions from '@configs/permissions';

export default [
  {
    title: 'K-Parcel',
    icon: 'CodepenIcon',
    children: [
      {
        title: 'Operations',
        route: 'k-parcel-operations',
        resource: Permissions.K_PARCEL,
      },
      {
        title: 'Performances',
        route: 'k-parcel-performances',
        resource: Permissions.K_PARCEL,
      },
      {
        title: 'Daily Volumes',
        route: 'k-parcel-daily-volumes',
        resource: Permissions.K_PARCEL,
      },
    ],
  },
]
