import store from "@/store";
import { fetcher, FetchMethod } from "@/libs/axios";
import { ref, onMounted, watch } from "@vue/composition-api";
import {
    getZonedDatesFromFullDates,
    getZonedYearMonthFromFullDate,
    getZonedYearFromFullDate,
    getLocalDateTimeString,
} from "@/utils/date-time-helper";
import { X_AXIS_MODE_DATE, X_AXIS_MODE_MONTH } from "@configs/filter";
import { chartNumberFormatter } from '@/utils/ui-helper';
import dayjs from 'dayjs';

export default function ({ chartApi, queryMonthOffset, queryParams }) {
    const isLoading = ref(false);
    const loadFail = ref(false);
    const lastUpdate = ref(null);
    const options = ref({});
    const url = ref(chartApi);
    const externalQueryParams = ref(null);

    onMounted(() => {
        fetchChart();
    });

    watch(
        () => store.getters[queryParams],
        function () {
            fetchChart();
        }
    );

    function onReload() {
        fetchChart();
    }

    function setOptions(response, queryParams) {
        options.value = {
            tooltip: {
                trigger: 'item',
            },
            series: [
                {
                    type: 'pie',
                    radius: ['40%', '70%'],
                    data: Object.keys(response).map(key => {
                        return { name: key, value: Math.round(response[key]) }
                    }),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                    avoidLabelOverlap: true,
                    label: {
                        formatter: '{b}: {c}\n({d}%)'
                    },
                }
            ]
        };
    }

    // function getWrapperQueryParams () {
    //     let queryParamsObj = { ...(externalQueryParams.value || store.getters[queryParams]) };

    //     if (queryMonthOffset != null && queryParamsObj.range == "current_month") {
    //         queryParamsObj.currentStartDate = dayjs(queryParamsObj.currentStartDate).add(queryMonthOffset, 'month')
    //         queryParamsObj.currentEndDate = queryParamsObj.currentStartDate.endOf('month');
    //         queryParamsObj.previousStartDate = dayjs(queryParamsObj.previousStartDate).add(queryMonthOffset, 'month')
    //         queryParamsObj.previousEndDate = queryParamsObj.previousStartDate.endOf('month');

    //         queryParamsObj.currentStartDate = queryParamsObj.currentStartDate.toISOString();
    //         queryParamsObj.currentEndDate = queryParamsObj.currentEndDate.toISOString();
    //         queryParamsObj.previousStartDate = queryParamsObj.previousStartDate.toISOString();
    //         queryParamsObj.previousEndDate = queryParamsObj.previousEndDate.toISOString();
    //     }
    //     return queryParamsObj;
    // }

    async function fetchChart() {
        isLoading.value = true;
        loadFail.value = false;

        try {
            const queryParamsObj = externalQueryParams.value || store.getters[queryParams]; //getWrapperQueryParams();
            const response = await fetcher(
                url.value,
                FetchMethod.GET,
                queryParamsObj
            );

            setOptions(response, queryParamsObj);

            lastUpdate.value = getLocalDateTimeString()
        } catch (e) {
            loadFail.value = true;
        } finally {
            isLoading.value = false;
        }
    }

    return {
        isLoading,
        loadFail,
        lastUpdate,
        options,
        onReload,

        url,
        externalQueryParams
    };
}
