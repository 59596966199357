import Permissions from '@configs/permissions';

export default [
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Accounts',
        route: 'setting-account',
        resource: Permissions.SETTING,
      },
      {
        title: 'Login Logs',
        route: 'setting-login-logs',
        resource: Permissions.SETTING,
      },
      // {
      //   title: 'My Preference',
      //   route: 'setting-filter-preference',
      //   resource: Permissions.SETTING_FILTER_PREFERENCE,
      // },
    ],
  },
]
