/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import sortation from './sortation'
import fulfillment from './fulfillment'
import kParcel from './k-parcel';
import setting from './setting';
import management from './management';
import crm from './crm';
import erp from './erp';
import jira from './jira';

// Array of sections
export default [
    ...management,
    ...crm,
    ...erp,
    ...sortation,
    ...fulfillment,
    ...kParcel,
    ...jira,
    ...setting
]
