import Permissions from '@configs/permissions';

export default [
  {
    title: 'CRM',
    icon: 'LayoutIcon',
    children: [
      {
        title: 'Deals',
        route: 'crm-deals',
        resource: Permissions.CRM,
      },
      {
        title: 'Pipelines',
        route: 'crm-pipeline',
        resource: Permissions.CRM,
        additionalResource: {crmRoleId: 1}
      },
      {
        title: 'Organisations',
        route: 'crm-organisation',
        resource: Permissions.CRM,
      },
      {
        title: 'Contacts',
        route: 'crm-contact',
        resource: Permissions.CRM,
      },
      {
        title: 'Products',
        route: 'crm-product',
        resource: Permissions.CRM,
      },
      {
        title: 'Users and Teams',
        route: 'crm-user',
        resource: Permissions.CRM,
        additionalResource: {crmRoleId: 1}
      },
      {
        title: 'Preferences',
        route: 'crm-preference',
        resource: Permissions.CRM,
      },
    ],
  },
]
