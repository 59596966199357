<template>
	<el-dialog
		:title="state.title == null ? 'Unknown Error' : state.title"
		:visible.sync="state.isOpen"
		width="800px"
		center
	>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="state.isOpen = false">Confirm</el-button>
		</span>
    <span>{{ state.message }}</span>
    <pre v-if="state.detail" style="margin-top: 24px;">{{ state.detail }}</pre>
	</el-dialog>
</template>

<script>
    import store from '@/store';
    import { computed, watch } from '@vue/composition-api';

	export default {
        setup(props, ctx) {
            const state = computed(() => {
                return store.state.app.errorDialog;
            });

            return {
                state
            }
        }
    };
</script>

<style scoped>
</style>