<template>
	<layout-vertical>
		
		<!-- 
    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
		<div class="h-100 w-100">
			<router-view />
			<dialog-pie-chart />
			<error-dialog/>
		</div>
	</layout-vertical>
</template>

<script>
	import ErrorDialog from "@common-components/error-dialog/ErrorDialog.vue";
	import DialogPieChart from "@common-components/chart/DialogPieChart.vue";
	import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
	import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
	import { $themeConfig } from "@themeConfig";
	

	export default {
		components: {
			ErrorDialog,
			AppCustomizer,
			LayoutVertical,
			DialogPieChart,
		},
		data() {
			return {
				showCustomizer: $themeConfig.layout.customizer,
			};
		},
	};
</script>
