<template>
	<el-dialog :visible.sync="isOpen" center width="90%">
		<div style="height: 60vh">
			<div class="container-fluid h-100">
				<div class="row h-100">
					<div class="col-lg-6 pb-1 pt-1">
						<chart
							:title="leftTitle"
							:last-update="leftLastUpdate"
							:options="leftOptions"
							:is-loading="leftIsLoading"
							:load-fail="leftLoadFail"
							@reload="leftOnReload"
						>
							<template slot="custom-top-left">
								<el-date-picker
									style="width: 150px"
									v-model="leftPickedDate"
									:type="leftPickerMode"
								/>
							</template>
						</chart>
					</div>

					<div class="col-lg-6 pb-1 pt-1">
						<chart
							:title="rightTitle"
							:last-update="rightLastUpdate"
							:options="rightOptions"
							:is-loading="rightIsLoading"
							:load-fail="rightLoadFail"
							@reload="rightOnReload"
						/>
					</div>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import store from "@/store";
	import usePieChart from "./usePieChart";
	import Chart from "@common-components/chart/Chart.vue";
	import { computed, watch, ref } from "@vue/composition-api";
	import {
		X_AXIS_MODE_DATE,
		X_AXIS_MODE_MONTH,
		X_AXIS_MODE_YEAR,
	} from "@configs/filter"; //Locations, Clients,
	import dayjs from "dayjs";

	export default {
		components: {
			Chart,
		},
		setup(props, ctx) {
			const isOpen = computed({
				get: () => store.state.app.dialogPieChart.isOpen,
				set: (val) =>
					store.commit("app/UPDATE_DIALOG_PIE_CHART", { isOpen: false }),
			});

			const leftTitle = computed(() => store.state.app.dialogPieChart.title);
			const rightTitle = computed(
				() =>
					store.state.app.dialogPieChart.title +
					" " +
					store.state.app.dialogPieChart.titlePostfix
			);
			const leftChart = usePieChart({ chartApi: null });
			const rightChart = usePieChart({ chartApi: null });
			const leftPickedDate = ref(null);

			watch(
				() => store.state.app.dialogPieChart.apiUrl,
				function () {
					if (store.state.app.dialogPieChart.queryParams != null) {
						rightChart.url.value =
							store.state.app.dialogPieChart.apiUrl;
						rightChart.externalQueryParams.value =
							store.state.app.dialogPieChart.queryParams;
						rightChart.onReload();

						let queryStartDate = store.state.app.dialogPieChart.queryParams.currentStartDate;
						leftPickedDate.value = dayjs(queryStartDate).subtract(1, store.state.app.dialogPieChart.queryParams.xAxisMode);
						leftChart.url.value = store.state.app.dialogPieChart.apiUrl;
						leftChart.externalQueryParams.value = store.state.app.dialogPieChart.queryParams;
					}
				}
			);

			watch(
				() => leftPickedDate.value,
				() => {
					const currentStartDate = dayjs(leftPickedDate.value);
					let currentEndDate;

					if (
						leftChart.externalQueryParams.value.xAxisMode ==
						X_AXIS_MODE_DATE
					) {
						currentEndDate = currentStartDate.endOf("date");
					} else if (
						leftChart.externalQueryParams.value.xAxisMode ==
						X_AXIS_MODE_MONTH
					) {
						currentEndDate = currentStartDate.endOf("month");
					} else {
						currentEndDate = currentStartDate.endOf("year");
					}

					leftChart.externalQueryParams.value = {
						...leftChart.externalQueryParams.value,
						currentStartDate: currentStartDate.toISOString(),
						currentEndDate: currentEndDate.toISOString(),
					};
					leftChart.onReload();
				}
			);

			const leftPickerMode = computed(() =>
				leftChart.externalQueryParams.value == null
					? "date"
					: leftChart.externalQueryParams.value.xAxisMode
			);

			return {
				isOpen,
				rightTitle,
				rightIsLoading: rightChart.isLoading,
				rightLoadFail: rightChart.loadFail,
				rightLastUpdate: rightChart.lastUpdate,
				rightOptions: rightChart.options,
				rightOnReload: rightChart.onReload,

				leftTitle,
				leftPickedDate,
				leftIsLoading: leftChart.isLoading,
				leftLoadFail: leftChart.loadFail,
				leftLastUpdate: leftChart.lastUpdate,
				leftOptions: leftChart.options,
				leftOnReload: leftChart.onReload,
				leftPickerMode,
			};
		},
	};
</script>
