import Permissions from '@configs/permissions';

export default [
  {
    title: 'Fulfillment',
    icon: 'TruckIcon',
    children: [
      {
        title: 'Management',
        route: 'fulfillment-management',
        resource: Permissions.FULFILLMENT,
      },
      // {
      //   title: 'Accrual',
      //   route: 'fulfillment-accrual',
      //   resource: Permissions.FULFILLMENT,
      // },
      {
        title: 'Operations',
        route: 'fulfillment-operations',
        resource: Permissions.FULFILLMENT,
      },
      {
        title: 'Real Time Data',
        children: [
          {
            title: 'Outbound Order',
            route: 'fulfillment-real-time-outbound-order',
            resource: Permissions.FULFILLMENT,
          }
        ]
      }
    ],
  },
]
