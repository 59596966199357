import Permissions from '@configs/permissions';


export default [
  {
    title: 'Management',
    icon: 'UsersIcon',
    children: [
      {
        title: 'P&L',
        route: 'management-p&l',
        resource: Permissions.MANAGEMENT,
      },
    ],
  },
]