import Permissions from '@configs/permissions';

export default [
  {
    title: 'Sortation',
    icon: 'PackageIcon',
    children: [
      // {
      //   title: 'Management',
      //   route: 'sortation-management',
      //   resource: Permissions.SORTATION,
      // },
      // {
      //   title: 'Operations',
      //   route: 'sortation-operations',
      //   resource: Permissions.SORTATION,
      // },
      // {
      //   title: 'Shopee Relabel',
      //   route: 'sortation-shopee-relabel',
      //   resource: Permissions.SORTATION,
      // },

      {
        title: 'Real Time Data',
        children: [
          {
            title: 'Shopee Relabel',
            route: 'sortation-shopee-relabel',
            resource: Permissions.SORTATION,
          },
          {
            title: 'Productivity Per Hour',
            route: 'sortation-real-time-productivity-per-hour',
            resource: Permissions.SORTATION,
          }
        ],
        resource: Permissions.SORTATION,
      }
    ],
  },
]
