import Permissions from '@configs/permissions';

export default [
  {
    title: 'Jira',
    icon: 'CodepenIcon',
    customIcon: '/jira.svg',
    children: [
      {
        title: 'Tasks',
        route: 'jira-tasks',
        resource: Permissions.JIRA,
      },
      {
        title: 'Realtime',
        route: 'jira-realtime',
        resource: Permissions.JIRA,
      },
      {
        title: 'Dashboard',
        route: 'jira-dashboard',
        resource: Permissions.JIRA,
      },
    ],
  },
]
